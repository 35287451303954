export enum appModes {
    CORONA_STUDY          = 'CoronaStudy',
    SHARED_CHART          = 'SharedChart',
    VACCINE_QUESTIONNAIRE = 'VaccineQuestionnaire'
}

export const appNames = {
    [appModes.CORONA_STUDY]:          'מחקר קורונה' as const,
    [appModes.SHARED_CHART]:          'תיק חולה משותף' as const,
    [appModes.VACCINE_QUESTIONNAIRE]: 'שאלון חיסונים' as const
}

export const appMode = appModes.VACCINE_QUESTIONNAIRE