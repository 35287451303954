<template>
    <nav>
        <div id='navRight'>
            <div id='banner' @click='$router.push("/")' style='cursor:pointer'>{{ appName }}</div>
            <div v-if='$route.params.patientId' id='patientIndicator'>
                <div>{{ patientIndicator.patient }}</div> / <div>{{ patientIndicator.title }}</div>
            </div>
        </div>
        <template v-if='$store.state.auth0.isAuthenticated || $store.state.firebase'>
            <div class='links' v-if='$route.path !== "/questions/new" && appMode === "SharedChart"'>
                <slot></slot>
            </div>
            <div id='navSlot'></div>
            <a @click='logout'>יציאה מהמערכת</a>
        </template>
        <!-- <div class='links' id='loginIcon' v-else-if='!/\/token/g.test($route.path)'>
            <router-link to='/login'><img src='/images/login.png' /></router-link>
        </div> -->
    </nav>
</template>

<script lang='ts'>
import { getPatientDisplay } from '@/helpers';
import { defineComponent } from '@vue/runtime-core';
import { getAuth, signOut } from '@firebase/auth';

export default defineComponent({
    computed: {
        patientIndicator(){
            const { patient } = this.$store.state;
            if(!patient)
                return {};

            let pageTitle = this.$route.meta.pageTitle as string;
            if(pageTitle.includes('{questionnaire_name}')){
                const { questionnaireId } = this.$route.params;
                const questionnaire = patient.questionnaires.find(q => q.id === questionnaireId);
                pageTitle = pageTitle.replace('{questionnaire_name}', questionnaire?.name || '');
            }
            return { patient: getPatientDisplay(patient), title: pageTitle };
        }
    },
    methods: {
        logout(){
            if(this.$firebase){ // FOR STUDY
                signOut(getAuth());
                return this.$router.replace('/');
            }
            
            this.$auth0.logout({ returnTo: window.location.origin });
        }
    }
});
</script>

<style lang="scss">
nav {
    height: 4rem;
	background-color: #fff;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.25rem;
	@include shadow(0.125,0.25);
    z-index: 3;

    & a, & .links > * {
		margin-left: .8rem;
		display: flex;

        &:not(:last-child)::after {
            content: '|';
            color: #d35e5e;
            margin-right: .8rem;
        }
	}
	& .links {
		display: flex;
	}
    & select {
        margin-right: 0.5rem;
        position: relative;
        top: -0.2rem;
    }
    & select, option {
        font-size: 12px;
    }

    & #navRight {
        display: flex;
        align-items: center;

        & #banner {
            color: #a0abaa;
            font-size: 25px;
            font-weight: 100;
            text-decoration: overline underline;
        }

        & #patientIndicator {
            font-weight: bold;
            margin-right: 4rem;
            font-size: 18px;
            display: flex;
            justify-content: center;
            background-color: $swatchC;
            padding: 0.5rem 1rem;
            box-shadow: 0px 0px 6px #ddd;
            min-width: 18rem;
            text-align: center;

            & > div {
                margin: 0 0.3rem;
            }
        }
    }
}
</style>
