<template>
    <navbar v-if='appMode === appModes.SHARED_CHART || $route.path.startsWith("/admin")' />
    <main :style='{ display: loading ? `none` : undefined }'>
        <router-view />
    </main>
    <div id='appLoading' v-if='loading && appMode === appModes.SHARED_CHART'>
        <loading-indicator />
    </div>
    <toast v-if='$store.state.toast'></toast>
    <impersonation-manager v-if='appMode === appModes.SHARED_CHART && isAdmin' :isAdmin='isAdmin' />
</template>

<script lang='ts'>
import { computed, defineAsyncComponent, defineComponent, onBeforeMount, provide, ref } from '@vue/runtime-core';
import Toast from './Toast.vue';
import Navbar from './Navbar.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { State } from '@/ts/state/State';
import { appModes } from '@/appModes';

export default defineComponent({
    setup() {
        const router = useRouter();
        const store = useStore<State>();
        const loading = ref(true);

        const impersonateDialogOpen = ref(false);
        provide('impersonateDialogOpen', impersonateDialogOpen);
        provide('setImpersonateDialog', (value: boolean) => impersonateDialogOpen.value = value);

        onBeforeMount(async () => {
            await router.isReady();
            loading.value = false;
        });

        return { loading, appModes, isAdmin: computed(() => store.state.auth0.user?.['http://sharedchart.care/auth/roles']?.includes('Admin')) }
    },
    components: {
        Toast,
        Navbar,
        ImpersonationManager: defineAsyncComponent(() => import('./ImpersonationManager.vue'))
    }
})
</script>

<style lang='scss'>
#appLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.75rem;

    &-track {
        background: transparent;
    }
    
    &-thumb {
        background: #bbb;
        &:hover {
            background: #aaa;
        }
    }
}

[aria-modal="true"] {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000;


    & > :first-child {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    & > :not(:first-child) {
        z-index: 2;
    }
}

.transparentInputs {
  & textarea, & .input {
      padding: 0.25rem 1rem;
      width: 95%;
      border: black solid;
      border-width: 0 1px;
      border-radius: $corner;
      background-color: transparent;
      box-shadow: none !important;

      position: relative;
      right: 2.5%;

      &:focus {
            border-width: 0 2px;
      }
  }
  & textarea {
      min-height: 3.25rem;
      overflow: hidden;
  }
}


#tooltip, #menuPopper {
	display: none;
    z-index: 50;

	&[data-show] {
		display: block;
	}
}

body {
	line-height: 1.6;
    color: #212529;
}

* {
	box-sizing: border-box;
}

label, fieldset {
    display: block;
	width: fit-content;

	&[multi-choice] {
		padding-right: 1rem;
		text-indent: -1.5rem;
	}
	&:not([multi-choice]){
		margin: 0 auto;
	}

    & select {
        min-width: 6rem;
    }
}

input:not([type]), [type="number"], [type="password"], [type="email"], [type="tel"], select, textarea, .dateContainer {
	display: block;
	padding: 0 0.5rem;
	border-radius: $corner;
	border: 1px #eee solid;
	height: 2rem;
	@include shadow(0.125,0.25,0,#ddd);
    font: inherit;
    background-color: #fff;

	&:focus {
		border: 1px #96b7d6 solid;
	}
}

textarea {
    height: initial;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.margins {
	margin: 3rem 0;
}

.bg1 {background-color: $bgOne}
.bg2 {background-color: $bgTwo}

main {
    width: 100vw;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 2;

    display: flex;
    position: relative;
}

form, body, main {
    margin: 0 auto;
}
fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

img	{
    border: 0;
}

.parent, header h1 {
    @extend .card;
    width: 95%;
	margin: 0 auto;
}

.parent {
    @include flexbox(between,column);
}

h1,h2,h3,h4 {
    margin: 0;
    font-weight: 100;
    text-align: center;
}
h1 {
    font-size: 1.25rem;
    background: rgba(242,203,203,1);
    padding: 1rem;
    @include corner(top);
}
h1.round, header h1 {
    @include corner(bottom);
    background: #f2cbcb !important;
}
h1, h2, .manageUsers {
	& .info {
		margin-right: 0.5rem;
		max-width: 1.2rem;
		transform: translateY(0.1rem);
		cursor: help;
	}
}
h2 {
    background-color: $bgOne;
    padding: 0.75rem;
    @include flexbox(center-all)
}
a, .a {
	color:#d35e5e;
	text-decoration:none;
	cursor: pointer;

	&:hover { color:#b44444; }
}

section, header {
    // width: $mainWidth;
    margin: 0 auto;
    position: relative;
    height: fit-content;
}

header {
    margin-top: 2rem;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font: inherit;

    &[study-button] {
        min-width: 50%;
        padding: .5rem 1rem;
        @include shadow;
        border-radius: $corner;

        &:hover {
            background-color: $swatchB;
        }
    }
}

.message {
	max-width: 70%;
    text-align: center;
	border-radius: $corner;
	background-color: $swatchA;
	padding: 0.75rem 1.5rem;
	margin: 0 auto 2rem;
	@include flexbox(between,center,column);
	@include shadow;
}

.card {
    background-color: $bgOne;
    @include shadow;
    border: $borderLight;
	border-radius: $corner;
}

.small { font-size: 0.75rem }

.categorizedList {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	grid-gap: 1.5rem;
	text-align: center;
	justify-content: center;
	padding: 2rem 2rem 0;

	& h3 {
		background-color: $swatchB;
		&:hover { background-color: $hoverB }
    }

	& h3, & ul li {
		margin: 0 auto;
		@include flexbox(center-all);
        padding: 0.5rem;
        border-radius: $corner;
        @include shadow;
		cursor: pointer;
		width: 90%;
	}

    & ul{
        padding: 1rem 0;
        & li {
            background-color: $swatchC;
            &:not(:first-child) {
                margin-top: 0.6rem;
            }
            &:hover { background-color: $hoverC; }
        }
    }
}

hr {
	height:1px;
	width: 25%;
	background-color: #ccc;
	border: none;
	margin: 0.1rem 0;
	margin: 0 auto;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.pointer {cursor: pointer}

.narrowBox {
	min-width: 20rem;
	max-width: 30rem;
	@extend .parent;
	width: auto;
	margin-bottom: 3rem;

	h2 { flex-wrap: wrap; }

	& .bg1 {
		width: fit-content;
		margin: auto;
		padding: 1rem;
	}

	& label {
		& input[type="radio"] {
			margin-left: 0.5rem;
		}
		&:not([multi-choice]):not(:first-child){
			margin: 1rem auto 1.5rem;
		}
	}

	& > button {
		min-width: 0;
		margin: 0 auto 2rem;
        @include shadow;
        background-color: $swatchB;
        border-radius: $corner;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: $hoverB;
        }
	}

	& div.error {
		width: 75%;
		margin: 0 auto;
		color: #f00;
		text-align: center;
		padding: 0.3rem;
		border-radius: $corner;
	}
}

.error:not(label) {
	border: 1px #f00 solid !important;
}

#padBottom {
    padding: 2rem;
}

.material-icons-round, .material-icons-outline {
    user-select: none;
    cursor: default;
}

@media only screen and (max-width: 768px) {
    section {
        width: 90vw;
    }

    nav {
        flex-direction: column;
        justify-content: center;

        & > *:not(:first-child) { margin-top: 0.7rem }
    }

    #page {
        overflow: auto;
    }

    .message {
        width: 70%;
    }

    .buttonContainer {
        padding: 0.75rem;
        justify-content: space-around;
    }
	.login {
		& input { margin-right: 0; }
		& label { @include flexbox(false,column); }
	}

    .legend {
        width: auto;
        max-width: 70vw;
    }

    .categorizedList {
        @include flexbox(center-all,column);
    }
    .categorizedList.chooser {
        width: 70vw;
        & > li {
            width: 100%;
        }
    }

    .patientFile > div {
        grid-template-columns: 1fr;
        & .vSpace {
            display: contents;
            & > div:not(:last-child) { margin: 0 }
        }
    }

    #loginIcon {
        display: none;
    }
}
</style>